#group-container-basic,
#group-container-advanced,
#group-container-layout,
#group-container-premium,
#group-container-data {
    display: grid;
    gap: 0.1rem;
}

span[data-buildertype] {
    font-size: 1rem;
}

.field label {
    font-size: 1rem !important;
}

.dwkit-collectioneditor tr td {
    font-size: 1rem !important;
}

.builder-group-button {
    width: 100%;
}

.form-subtitles {
    width: 100% !important;
    text-align: center;
    background: #C5D9F1;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 4px 0px;
    font-weight: bold;
}

.signature-pad-body {
    height: 80px !important;
    min-width: 150px !important;
}

.formio-component-signature>.col-form-label {
    display: none !important;
}

.f-weight-bold {
    font-weight: bold !important;
}

.header-table {
    background: #C5D9F1;
}

.header-table-form>.formio-component-table3>.tr:first-child {
    background: #C5D9F1 !important;
}

.d-grid-fs-sm {
    font-size: 11px !important;
}

.d-grid .input-group-append {
    display: none !important;
}

.d-grid-w-150 td {
    min-width: 150px !important;
}

.col-form-label {
    font-size: 1rem;
}


@media (max-width: 425px) {
    .formio-component {
        font-size: 0.8rem;
    }

    .formio span {
        font-size: 0.75rem !important;
    }


    span[data-buildertype] {
        font-size: 1rem;
    }

    .field label {
        font-size: 1rem !important;
    }

    .dwkit-collectioneditor tr td {
        font-size: 1rem !important;
    }
}


@media (max-width: 768px) {
    .formio-component {
        font-size: 1.2rem;
    }

    .form-check-label {
        font-size: 1rem !important;
    }

    img.formio-component-htmlelement {
        width: 110px;
    }
}


@media (max-width: 1440px) {
    .formio-component {
        font-size: 1rem;
    }

    .formio-component th {
        font-size: 14px;
    }

    span[data-buildertype] {
        font-size: 0.75rem;
    }

    .field label {
        font-size: 0.75rem !important;
    }

    .dwkit-collectioneditor tr td {
        font-size: 0.75rem !important;
    }

}


@media (max-width: 2560px) {
    .formio-component {
        font-size: 1.1rem;
    }
}