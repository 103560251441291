@import '~bootstrap/dist/css/bootstrap.css';
@import '~formiojs/dist/formio.full.min.css';


.App {
    text-align: left;
}

.formio-component * {
    word-wrap: break-word!important;
    word-break: break-word!important;
}

.formarea {
    border: 2px dashed lightgray;
}

#group-container-basic, #group-container-advanced,
#group-container-layout, #group-container-premium,
#group-container-data {
    display: grid;
    gap: 0.1rem;
}

.builder-group-button {
    width: 100%;
}

.form-subtitles {
    width: 100% !important;
    text-align: center;
    background: #C5D9F1;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 4px 0px;
    font-weight: bold;
}

.signature-pad-body {
    height: 80px !important;
    min-width: 150px !important;
}

.f-weight-bold {
    font-weight: bold !important;
}

.header-table {
    background: #C5D9F1;
}

.header-table-form > .formio-component-table3 > .tr:first-child {
    background: #C5D9F1 !important;
}


.col-form-label {
    font-size: 15px;
}

.field-required {
    font-size: 15px;
}

.field-required th {
    font-size: 15px;
}

.formio-component th {
    font-size: 15px;
}

.form-check-label {
    font-size: 15px;
}

.title-form p {
    font-size: 16px;
}

@media (max-width: 426px) {
    .formio-component {
        font-size: 12px !important;
    }

    .col-form-label {
        font-size: 12px;
    }
    
    .field-required {
        font-size: 12px;
    }
    
    .formio-component th {
        font-size: 12px !important;
    }
    
    .form-check-label {
        font-size: 12px;
    }
    
}


@media (max-width: 768px) {
    .formio-component {
        font-size: 12px;
    }

    .form-check-label {
        font-size: 1rem !important;
    }
    
    img.formio-component-htmlelement {
        width: 110px;
    }

    .col-form-label {
        font-size: 12px;
    }
    
    .field-required {
        font-size: 12px;
    }
    
    .formio-component th {
        font-size: 12px !important;
    }
    
    .form-check-label {
        font-size: 12px;
    }

    /* title */
    .title-form p {
       font-size: 12px !important;
    }
}


@media (max-width: 1440px) {
    .formio-component {
        font-size: 14px;   
    }

    .col-form-label {
        font-size: 14px;
    }
    
    .field-required {
        font-size: 14px;
    }
    
    .field-required th {
        font-size: 14px;
    }
    
    .formio-component th {
        font-size: 14px;
    }
    
    .form-check-label {
        font-size: 14px;
    }
    
    /* title */
    .title-form p {
        font-size: 14px;
    }
}


@media (max-width: 2560px) {
    .formio-component {
        font-size: 1.1rem;
    }
}

/* MIN WIDTH */

.min-col-300 {
    min-width: 300px !important;
}

.min-col-400 {
    min-width: 400px !important;
}

.min-col-500 {
    min-width: 500px !important;
}

.min-col-700 {
    min-width: 700px !important;
}

.min-col-800 {
    min-width: 800px !important;
}

/* FONT SIZE */

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

/* DATA GRID STYLES */

.d-grid-fs-12 {
    font-size: 12px !important;
}

.d-grid .input-group-append {
    display: none !important;
}

.d-grid-w-150 td {
    min-width: 150px !important;
}

.d-grid-signature > .col-form-label {
    display: none !important;
}

.img-right-container {
    height: 100% !important;
    width: 100% !important;
}

.img-right-bottom {
    text-align: right !important;
    align-self: flex-end !important;
}

/* Remove right padding for selects */
.choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 10px !important;
}

/* text area  */
.custom-table textarea {
	min-height: 70px !important;
	height: 70px !important;
}

.custom-table textarea::-webkit-scrollbar-track {
    background-color: transparent;
}

.custom-table textarea::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.formio-component-multiple .choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
  max-height: 90px;
}